import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSocketContext } from './SocketProvider'
import { useEventContext } from './EventProvider'
import { loadEvent } from '../../api/app/events'

const PrivateSocketListeners = ({ children }) => {
	const dispatch = useDispatch()
	const { event } = useEventContext()
	const {
		users: { socket: usersSocket, isConnected: usersConnected },
		staff: { socket: staffSocket, isConnected: staffConnected },
	} = useSocketContext()

	/** define handlers */
	const handleMediaItemUpdated = (payload) => {
		dispatch({
			type: 'SOCKET_UPDATE_MEDIA',
			payload: payload,
		})
	}

	const handleMediaItemAdded = (payload) => {
		dispatch({
			type: 'SOCKET_ADD_MEDIA',
			payload: payload,
		})
	}

	const handleNewFunder = (payload) => {
		dispatch({
			type: 'SOCKET_ADD_FUNDER',
			payload: payload,
		})
	}

	const handleRSVPUpdated = (payload) => {
		dispatch({
			type: 'SOCKET_UPDATE_RSVP',
			payload: payload,
		})
	}

	const handleLoadEvent = (payload) => {
		// console.info('RECEIVED reloadCurrentEvent EVENT!', {payload, event})
		if (payload.data?.id == event.id || payload.data?.uuid == event.uuid) {
			dispatch(loadEvent(event.uuid))
		}
	}

	const handleBackgroundMusicUpdate = (payload) => {
		dispatch({
			type: 'SOCKET_EVENT_BACKGROUNDMUSIC',
			payload: payload,
		})
	}

	const handleThemeTemplateIdUpdate = (payload) => {
		if (payload.data?.id == event.id) {
			dispatch({
				type: 'SOCKET_EVENT_THEME',
				payload: payload,
			})
		}
	}

	const handleMediaSortingOrderUpdate = (payload) => {
		if (payload.data?.id == event.id) {
			dispatch({
				type: 'SOCKET_EVENT_SORTING_ORDER',
				payload: payload,
			})
		}
	}

	const handleEventCoverPhotoUpdated = (payload) => {
		if (payload.data?.id == event.id) {
			dispatch({
				type: 'UPDATE_EVENT_SUCCESS',
				payload: payload,
			})
		}
	}

	const handleEventMediaItemDeleted = (payload) => {
		dispatch({
			type: 'SOCKET_DELETE_MEDIA_SUCCESS',
			payload: payload,
		})
	}

	const handleRenderJobState = (payload) => {
		dispatch({
			type: 'SOCKET_UPDATE_RENDERJOB',
			payload: payload,
		})
	}

	/** register listeners for users namespace (on mount only) */
	useEffect(() => {
		// join users room for this specific event
		usersSocket.emit('join', `events:${event.id}`)

		usersSocket.on('eventMediaItemUpdated', handleMediaItemUpdated)
		usersSocket.on('eventMediaItemAdded', handleMediaItemAdded)
		usersSocket.on('eventMediaItemDeleted', handleEventMediaItemDeleted)
		usersSocket.on('eventFunderAdded', handleNewFunder)
		usersSocket.on('eventRSVPUpdated', handleRSVPUpdated)
		usersSocket.on('finalizeSuccess', handleLoadEvent)
		usersSocket.on('reloadCurrentEvent', handleLoadEvent)
		usersSocket.on('backgroundMusicUpdate', handleBackgroundMusicUpdate)
		usersSocket.on('themeTemplateIdUpdate', handleThemeTemplateIdUpdate)
		usersSocket.on('mediaSortingOrderUpdate', handleMediaSortingOrderUpdate)
		usersSocket.on('eventCoverPhotoUpdated', handleEventCoverPhotoUpdated)
		usersSocket.on('renderJobState', handleRenderJobState)

		/** deregister listeners on unmount */
		return () => {
			// leave room for this specific event
			usersSocket.emit('leave', `events:${event.id}`)

			usersSocket.off('eventMediaItemUpdated')
			usersSocket.off('eventMediaItemAdded')
			usersSocket.off('eventMediaItemDeleted')
			usersSocket.off('eventFunderAdded')
			usersSocket.off('eventRSVPUpdated')
			usersSocket.off('finalizeSuccess')
			usersSocket.off('reloadCurrentEvent')
			usersSocket.off('backgroundMusicUpdate')
			usersSocket.off('themeTemplateIdUpdate')
			usersSocket.off('mediaSortingOrderUpdate')
			usersSocket.off('eventCoverPhotoUpdated')
			usersSocket.off('renderJobState')
		}
	}, [])

	/** register listeners for staff namespace (on mount only) */
	useEffect(() => {
		// join staff room for this specific event
		staffSocket.emit('join', `events:${event.id}`)

		staffSocket.on('eventMediaItemUpdated', handleMediaItemUpdated)
		staffSocket.on('eventMediaItemAdded', handleMediaItemAdded)
		staffSocket.on('eventMediaItemDeleted', handleEventMediaItemDeleted)
		staffSocket.on('eventRSVPUpdated', handleRSVPUpdated)
		staffSocket.on('finalizeSuccess', handleLoadEvent)
		staffSocket.on('reloadCurrentEvent', handleLoadEvent)
		staffSocket.on('backgroundMusicUpdate', handleBackgroundMusicUpdate)
		staffSocket.on('themeTemplateIdUpdate', handleThemeTemplateIdUpdate)
		staffSocket.on('mediaSortingOrderUpdate', handleMediaSortingOrderUpdate)
		staffSocket.on('eventCoverPhotoUpdated', handleEventCoverPhotoUpdated)
		staffSocket.on('renderJobState', handleRenderJobState)

		/** deregister listeners on unmount */
		return () => {
			// leave room for this specific event
			staffSocket.emit('leave', `events:${event.id}`)

			staffSocket.off('eventMediaItemUpdated')
			staffSocket.off('eventMediaItemAdded')
			staffSocket.off('eventMediaItemDeleted')
			staffSocket.off('eventRSVPUpdated')
			staffSocket.off('finalizeSuccess')
			staffSocket.off('reloadCurrentEvent')
			staffSocket.off('backgroundMusicUpdate')
			staffSocket.off('themeTemplateIdUpdate')
			staffSocket.off('mediaSortingOrderUpdate')
			staffSocket.off('eventCoverPhotoUpdated')
			staffSocket.off('renderJobState')
		}
	}, [])

	return children
}

export default PrivateSocketListeners

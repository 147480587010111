/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA, getJSON } from 'redux-api-middleware'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as Sentry from '@sentry/react'
import * as types from '../constants'
import { addFlashMessage } from '../behaviors/flash-messages'
import { makeActionCreator } from '@vidday/utils'
import { retrieveURL } from '@vidday/utils'
// import { fetchProfile } from '../profile'
import { addEvent, clearTemporaryEvent } from '../events'
import { fetchPreferences } from '../preferences'
import { history } from '../../../history'
import { fetchNotifications } from '../../notifications/actions'
import redirectToNextIfWindow from '../../../v2/utils/redirectToNextIfWindow'

/* ******************************
 * LOAD ENTITIES RELATED TO THE AUTHED USER
 ***************************** */

export function loadUserEntities(userId) {
	return function (dispatch, state) {
		// dispatch(fetchPreferences(userId))
		// dispatch(fetchProfile(userId))
		dispatch(
			fetchNotifications({
				orderBy: 'createdAt',
				orderByDir: 'desc',
				pageSize: 50,
				page: 0,
			})
		)
		// dispatch(fetchEvents(userId))
		// dispatch(fetchInvoices(userId))
		// dispatch(fetchTracks())
	}
}

/* ******************************
 * LOAD AUTHED USER DATA
 ***************************** */

export function loadAuthedUser(userId) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.FETCH_AUTHED_USER_REQUEST,
					{
						type: types.FETCH_AUTHED_USER_SUCCESS,
						payload: (action, state, res) => {
							return getJSON(res).then(function (json) {
								if (json.data) {
									const user = json.data
									const isImpersonated = json.isImpersonated
									const message = `User ${user.email} ${
										isImpersonated ? '[IMPERSONATED]' : ''
									} loaded client-side after hydration.`
									dispatch(setAuthenticatedUser(user, message, isImpersonated))
								} else {
									const message = 'Not authed. Proceeding as guest.'
								}
							})
						},
					},
					types.FETCH_AUTHED_USER_FAILURE,
				],
				endpoint: `${retrieveURL()}/api/v2/auth/who-am-i`,
				credentials: 'include',
				method: 'GET',
			},
		})
	}
}

/* ******************************
 * SET AUTHENTICATED USER
 ***************************** */

// reserve for server side ?
export const setAuthenticatedUser = makeActionCreator(types.SET_AUTHENTICATED_USER, 'user', 'message', 'isImpersonated')

/* ******************************
 * LOGIN USER
 ***************************** */
export function setActivationRequiredFalse() {
	return { type: 'RESET_ACTIVATION_REQUIRED_VARIABLE', payload: { activationRequired: false } }
}
export function setActivationRequiredTrue() {
	return { type: 'RESET_ACTIVATION_REQUIRED_VARIABLE', payload: { activationRequired: true } }
}
export function resetTemporaryUser() {
	return { type: 'RESET_TEMPORARY_USER', payload: {} }
}

function loginUserSuccess(json, dispatch, state, successRedirect = '/dashboard') {
	let message = json.message || 'Login successful.'

	// Check for temporary event;
	var temp = state.temporaryEvent.data

	if (temp) {
		//console.log('TEMPORARY EVENT from *loginUserSuccess* [json.data]:', json.data)
		temp.creatorId = json.data.id ? json.data.id : null

		dispatch(addEvent(temp)).then((response) => {
			// Get data of returned event entity
			let eventData = Object.values(response.payload.entities.events)[0]
			// remove temp event
			dispatch(clearTemporaryEvent())
			// redirect to event page, temporaily logged in, still awaiting account activation.
			history.push(`/event/${eventData.uuid}?auth=email`)
		})
	} else {
		history.push(successRedirect)
		// dispatch(push(successRedirect))
	}
	// dispatch setauthenticatesuser
	dispatch(setAuthenticatedUser(json.data, json.message))
	// load authenticated user entities
	dispatch(loadUserEntities(json.data.id))
	// send flash for sucess
	dispatch(addFlashMessage('success', message))
	return json
}

// Log a User in
export function loginUser(data, successRedirect) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					{
						type: types.LOGIN_USER_REQUEST,
						payload: (data) => {},
					},
					{
						type: types.LOGIN_USER_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								return loginUserSuccess(json, dispatch, state, successRedirect)
							})
						},
					},
					{
						type: types.LOGIN_USER_FAILURE,
						payload: (action, state, res) => {
							// grab attempted login email from login form state
							//const attemptedEmail = state.form.login_form.values.email || state.form.register_form.values.email
							return res.json().then((json) => {
								let errorMessage = json.message || json.error || 'Login failed.'
								if (res.status === 400) {
									// dispatch(resendVerificationEmail(state.form.login_form.values.email))
									return { activationRequired: true, message: errorMessage }
								} else if (res.status === 404) {
									// else 404 is no account found
									let callToAction = {
										text: 'Create an account?',
										callback: function () {
											redirectToNextIfWindow('/auth/register')
											// history.push('/auth/register')
											// dispatch(push('/register'))
										},
									}
									dispatch(addFlashMessage('error', errorMessage, callToAction))
								} else if (res.status === 401) {
									// else 401 is bad credentials
									let callToAction = {
										text: 'Forgot your password?',
										callback: function () {
											redirectToNextIfWindow('/auth/forgot')
											// dispatch(push('/forgot'))
										},
									}
									dispatch(addFlashMessage('error', errorMessage, callToAction))
								} else {
									dispatch(addFlashMessage('error', errorMessage))
								}
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/auth/login',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * UPDATE PASSWORD
 ***************************** */

export function updatePassword(data) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UPDATE_PASSWORD_REQUEST,
					{
						type: types.UPDATE_PASSWORD_SUCCESS,
						payload: (action, state, res) => {
							return getJSON(res).then(function (json) {
								dispatch(addFlashMessage('success', json.message))
								return json.data
							})
						},
					},
					{
						type: types.UPDATE_PASSWORD_FAILURE,
						payload: (action, state, res) => {
							return getJSON(res).then(function (json) {
								dispatch(addFlashMessage('error', json.error))
								return json.data
							})
						},
					},
				],
				endpoint: `${retrieveURL()}/api/v2/users/${data.id}/change-password`,
				method: 'PUT',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * LOGOUT USER
 ***************************** */

export function logout() {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [types.LOGOUT_REQUEST, types.LOGOUT_SUCCESS, types.LOGOUT_FAILURE],
				endpoint: retrieveURL() + '/api/v2/auth/logout',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
			},
		})
	}
}

/* ******************************
 * LOGOUT & REDIRECT USER
 ***************************** */

export function logoutAndRedirect() {
	return (dispatch, state) => {
		dispatch(logout())
		dispatch(addFlashMessage('success', 'You have been logged out.'))
	}
}

/* ******************************
 * CREATE A USER
 * wrap RSAA within dispatch for access to dispatch
 * within the Failure callback
 ***************************** */

export function postUser(data, successRedirect, successCallback) {
	return function (dispatch, getState) {
		dispatch({
			[RSAA]: {
				types: [
					types.REGISTER_REQUEST,
					{
						type: types.REGISTER_SUCCESS,
						payload: function (action, state, res) {
							return res.json().then((json) => {
								// Callback for actions looking for a promise return.
								// Check for temporary event;
								var temp = state.temporaryEvent.data

								if (temp) {
									//console.log('TEMPORARY EVENT from *postUser* [json.data]:', json.data)
									temp.creatorId = json.data.id ? json.data.id : null

									dispatch(addEvent(temp)).then((response) => {
										//console.log('From New user Temp event', response)
										// Set Temp as new complete eventModel
										const newEvent = response.payload.entities.events[response.payload.result]
										// remove temp event
										dispatch(clearTemporaryEvent())

										if (successCallback) {
											successCallback({ user: json.data, event: newEvent })
										}
									})
								} else {
									if (successCallback) {
										successCallback({ user: json.data, event: temp })
									}
								}

								if (json.activationRequired && data.sendEmail) {
									redirectToNextIfWindow('/auth/login?activation_required=true')
									// redirect to event page, temporaily logged in, still awaiting account activation.
									// history.push('/auth/login?activationRequired=true')
									// dispatch(push('/login?activationRequired=true'))
									return json
								} else {
									return json
								}
							})
						},
					},
					{
						type: types.REGISTER_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								let errorMessage = json.error || 'Error creating user.'
								if (res.status === 400) {
									// 400 account found
									if (successCallback) {
										successCallback({ user: json.data, event: null })
									}
									let callToAction = {
										text: 'Log in?',
										callback: function () {
											redirectToNextIfWindow('/auth/login')
										},
									}
									// temporarily logging to sentry - without throwing an exception.
									Sentry.captureException(new Error(errorMessage))
									dispatch(addFlashMessage('error', errorMessage, callToAction))
								} else {
									// temporarily logging to sentry - without throwing an exception.
									Sentry.captureException(new Error(errorMessage))
									dispatch(addFlashMessage('error', errorMessage))
								}
								return data
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/auth/register',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * RECOVER PASSWORD
 * data should be of the form {email:'email address'}
 ***************************** */

export function forgotPassword(data) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.FORGOT_PASSWORD_REQUEST,
					{
						type: types.FORGOT_PASSWORD_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((data) => {
								let message = data.message || 'Password reset email sent.'
								dispatch(addFlashMessage('success', message))
								return data
							})
						},
					},
					{
						type: types.FORGOT_PASSWORD_FAILURE,
						payload: (action, state, res) => {
							res.json().then((data) => {
								let errorMessage = data.error || 'Unable to send reset password email.'
								if (res.status === 400) {
									// else 404 is no account found
									let callToAction = {
										text: 'Create an account?',
										callback: function () {
											redirectToNextIfWindow('/auth/login')
										},
									}
									dispatch(addFlashMessage('error', errorMessage, callToAction))
								} else {
									dispatch(addFlashMessage('error', errorMessage))
								}
								return data
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/auth/forgot-password',
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

export function resendVerificationEmail(email) {
	// console.log("Resending verification email for ", email)
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.RESEND_VERIFICATION_REQUEST,
					{
						type: types.RESEND_VERIFICATION_SUCCESS,
						payload: (action, state, res) => {
							res.json().then((data) => {
								let message = data.message || 'Verification email sent successful.'
								dispatch(addFlashMessage('success', message))
								return data
							})
						},
					},
					{
						type: types.RESEND_VERIFICATION_FAILURE,
						payload: (action, state, res) => {
							res.json().then((data) => {
								let message = data.message || 'Account does not exist or has already been activated.'
								dispatch(addFlashMessage('info', message))
								return data
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/auth/resend-verification',
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: email }),
			},
		})
	}
}

export function resetPassword(token, data) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.RESET_PASSWORD_REQUEST,
					{
						type: types.RESET_PASSWORD_SUCCESS,
						payload: (action, state, res) => {
							res.json().then((json) => {
								let message = json.message || 'Password reset successful.'
								dispatch(addFlashMessage('success', message))
								// redirect them to the login form
								let urlSafeEmail = json.data.email ? encodeURIComponent(json.data.email) : null
								let redirect = urlSafeEmail
									? `/auth/login?email=${urlSafeEmail}#resetsuccess`
									: '/auth/login#resetsuccess'
								console.log('redirect action of reset password, ', redirect, urlSafeEmail)
								redirectToNextIfWindow(redirect)
								// history.push(redirect)
								// dispatch(push(redirect))
								return json
							})
						},
					},
					{
						type: types.RESET_PASSWORD_FAILURE,
						payload: (action, state, res) => {
							res.json().then((data) => {
								let errorMessage = data.error || 'Password reset failed.'
								dispatch(addFlashMessage('error', errorMessage))
								// redirect them back to password reset form
								let redirect = `/auth/forgot`
								history.push(redirect)
								return data
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/auth/reset-password/' + token,
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * ACTIVATE USER ACCOUNT
 ***************************** */

export function activateAccount(token, redirect = '/dashboard') {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.ACTIVATE_ACCOUNT_REQUEST,
					{
						type: types.ACTIVATE_ACCOUNT_SUCCESS,
						payload: (action, state, res) => {
							res.json().then((json) => {
								// Wait a bit
								setTimeout(() => {
									// let message = json.message || 'Your account is now activated.'
									// dispatch(addFlashMessage('success', message))
									// dispatch setauthenticatesuser
									dispatch(setAuthenticatedUser(json.data, json.message))
									// load authenticated user entities
									dispatch(loadUserEntities(json.data.id))
									// Choose where to redirect; server side 'redirectTo' is priority
									const redirectTo = json.redirectTo ? json.redirectTo : redirect // doesn't seem to work as redux gets update faster than this timeout with 'activationRequired'
									return history.push(redirectTo) // dispatch(push(redirectTo))
								}, 5000)
								return json.data
							})
						},
					},
					{
						type: types.ACTIVATE_ACCOUNT_FAILURE,
						payload: (action, state, res) => {
							res.json().then((data) => {
								let errorMessage = data.error || 'We could not activate your account.'
								dispatch(addFlashMessage('error', errorMessage))
								return data
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/auth/activate/' + token,
				method: 'GET',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
			},
		})
	}
}

/* ******************************
 * CHECK DUPLICATE ACCOUNT EXISTS
 ***************************** */

export function checkAccount(email, muteAlert = false) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					{
						type: types.CHECK_ACCOUNT_EMAIL_REQUEST,
						payload: email,
					},
					{
						type: types.CHECK_ACCOUNT_EMAIL_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (json.success) {
									//dispatch(addFlashMessage('success', json.message))
								} else {
									if (!muteAlert) dispatch(addFlashMessage('info', 'Welcome back!'))
								}
								return json
							})
						},
					},
					{
						type: types.CHECK_ACCOUNT_EMAIL_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((data) => {
								let errorMessage = data.error || 'We could not verify your account.'
								dispatch(addFlashMessage('error', errorMessage))
								return data
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/auth/check-user',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: email }),
			},
		})
	}
}

/* ******************************
 * CHECK DUPLICATE ACCOUNT EXISTS EXCEPT FOR THE AUTHED USER
 * (USED FOR WHEN USER UPDATE ITS EMAIL ADDRESS)
 * Used to check that the new email address
 * chosen by a user is not a primary or
 * secondary address of himself or another user.
 ***************************** */

export function checkAccountExceptOwned(email) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					{
						type: types.CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_REQUEST,
						payload: email,
					},
					{
						type: types.CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (!json.success) {
									dispatch(addFlashMessage('error', json.message))
								}
								return json
							})
						},
					},
					{
						type: types.CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								let errorMessage = json.message || 'We could not verify your account.'
								dispatch(addFlashMessage('error', errorMessage))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/checkaccountexpectowned',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: email }),
			},
		})
	}
}

/* ******************************
 * UPDATE USER DATA
 ***************************** */

export function updateUser(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UPDATE_AUTHED_USER_REQUEST,
					{
						type: types.UPDATE_AUTHED_USER_SUCCESS,
						payload: (action, state, res) => {
							return getJSON(res).then(function (json) {
								dispatch(logoutAndRedirect())
							})
						},
					},
					types.UPDATE_AUTHED_USER_FAILURE,
				],
				credentials: 'include',
				endpoint: `${retrieveURL()}/api/v2/users/${data.id}`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * UPDATE USER EMAIL DATA
 ***************************** */

export function updatePrimaryEmailAddress(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UPDATE_AUTHED_USER_PRIMARY_EMAIL_REQUEST,
					{
						type: types.UPDATE_AUTHED_USER_PRIMARY_EMAIL_SUCCESS,
						payload: (action, state, res) => {
							return getJSON(res).then(function (json) {
								dispatch(logoutAndRedirect())
							})
						},
					},
					{
						type: types.UPDATE_AUTHED_USER_PRIMARY_EMAIL_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								let errorMessage = json.message || 'We could not verify your account.'
								dispatch(addFlashMessage('error', errorMessage))
								return json
							})
						},
					},
				],
				credentials: 'include',
				endpoint: `${retrieveURL()}/api/v2/users/${data.id}/change-email`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

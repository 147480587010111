import useSSR from 'use-ssr'

/**
 * hook to push events into GTM data layer. Requires an `event` value and then any arbitrary data
 * @param {object} data
 * @param {string} data.event the GTM event name
 */
export const useDataLayerPush = (data) => {
	const { isBrowser } = useSSR()
	if (isBrowser) {
		let dataLayer = window.dataLayer || []
		dataLayer.push(data)
	}
}

export default useDataLayerPush

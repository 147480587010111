import { createSlice } from '@reduxjs/toolkit'
import { CART } from '../../api/checkout/constants'

const initialState = {
	/** Processing status */
	isFetching: true,
	/** Cart status */
	status: null, // or error, success
	statusMessage: '',
	/** Cart data */
	data: null,
	/** Hold a copy of the video product of the cart */
	videoProduct: null,
	// isRepublish:false,
	// republishDiscount:0,
	lineItems: [],
	checkoutSession: null,
}

const CartSlice = createSlice({
	initialState: initialState,
	name: 'cart',
	extraReducers: {
		[CART.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[CART.FETCH_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			state.data = action.payload.data
			state.lineItems = action.payload.data.lineItems
			// let videoItem = action.payload.data.lineItems.find((el, i) => el.group == 'video')
			// if(videoItem) {
			// 	state.isRepublish = videoItem.isRepub ? true: false;
			// 	state.republishDiscount = videoItem.isRepub ? videoItem.discount: 0;
			// }
		},
		[CART.FETCH_FAILED]: (state, action) => {
			state.isFetching = false
			state.data = null
			state.status = 'error'
			state.statusMessage = 'An error happened while retrieving the cart...'
		},
		[CART.UPDATE_REQUESTED]: (state, action) => {
			state.isFetching = true
		},

		[CART.UPDATE_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			state.data = action.payload.data
		},
		[CART.ADD_ITEM_REQUESTED]: (state, action) => {
			state.isFetching = true
		},

		[CART.ADD_ITEM_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			state.data.items = action.payload.data.items
			state.data.lineItems = action.payload.data.lineItems
			state.data.upsells = action.payload.data.upsells
		},
		[CART.REMOVE_ITEM_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[CART.REMOVE_ITEM_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			state.data.items = action.payload.data.items
			state.data.lineItems = action.payload.data.lineItems
			state.data.upsells = action.payload.data.upsells
		},
		[CART.POST_CHECKOUT_SESSION_SUCCEEDED]: (state, action) => {
			state.checkoutSession = action.payload.data
		},
	},
	reducers: {
		/** Helper to set a reference to the video product contained in the cart for ease of use */
		setVideoProduct: (state, action) => {
			state.videoProduct = action.payload
		},
	},
})

export default CartSlice.reducer

export const { setVideoProduct } = CartSlice.actions

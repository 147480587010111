import React from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventRecipients } from '../../../hooks/useRecipients'
import useCoverPhoto from '../../../hooks/useCoverPhoto'
import config from '../../../../../client/config.client'
// import usePublishedMedia from '../../../hooks/usePublishedMedia'

// extends dayjs with utc and timezone plugins.
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

const MetaTagsPublic = () => {
	/** Retrieve current event global state */
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const coverPhoto = useCoverPhoto()
	// const publishedMedia = usePublishedMedia()

	const location = useLocation()

	if (!event || !event.uuid) {
		return <></>
	}

	/** Set temp user off URI params - TODO: Maybe place this into a hook */
	const search = new URLSearchParams(location.search)

	const url = `${config.baseUrl}/e/${event.uuid}`
	const privateKey = search.get('v')
	const isPrivateLink = event && event.isPrivate && event.privateKey && privateKey == event.privateKey
	const buildDocumentImage = coverPhoto?.signedPreviewUrl
		? coverPhoto?.signedPreviewUrl
		: `https://share.vidday.com/assets/images/Vidday-The-Surprise-Video-Gift_og.jpg`

	// const publishedMediaViewable =
	// 	event &&
	// 	publishedMedia &&
	// 	event.status == 'published' &&
	// 	((event.isPrivate && event.privateKey && privateKey == event.privateKey) || !event.isPrivate)
	// const buildDocumentVideo = publishedMediaViewable ? publishedMedia.signedPreviewUrl : false

	const buildDocumentTitle = () => {
		let title = `Join in on group video message for ${recipients.formatted}!`
		const titles = {
			anniversary: `🥂 Wish ${recipients.formatted} a Happy Anniversary!`,
			babyshower: `👶 Send ${recipients.formatted} warm wishes to welcome their bundle of joy.`,
			birthday: `🎉 Join in on a personalized Birthday VidDay for ${recipients.formatted}!`,
			farewell: `👋 Wish ${recipients.formatted} a sincere farewell!`,
			fathers: `🦸‍♂️ Let's celebrate ${recipients.formatted} with a Father's Day VidDay!`,
			getwell: `💗 Send your messages for ${recipients.formatted}.`,
			graduation: `🎓 ${recipients.formatted} is graduating, send your congratulations message!`,
			mothers: `🦸‍♀️ Let's celebrate ${recipients.formatted} with a Mother's Day VidDay!`,
			thankyou: `🙏 Let's show appreciation for ${recipients.formatted} with a Thank You VidDay.`,
			wedding: `💍 Send your warm wishes to ${recipients.formatted} to celebrate their marriage!`,
			other: `Join in our group video for ${recipients.formatted}`,
		}

		if (titles[event.occasion] != undefined) {
			title = titles[event.occasion]
		}

		return event?.id
			? event.status == 'published' || event.status == 'publishing'
				? isPrivateLink
					? `A heartfelt VidDay video for you ${recipients.formatted}!`
					: `Watch this VidDay video for ${recipients.formatted}!`
				: title
			: `Create Your Own Gift Video for Special Occasions | VidDay 🎁`
	}

	let eventTimezone = event?.timezone ? event?.timezone : dayjs.tz.guess()

	const eventDeadline = event?.deadline
		? ' before ' + dayjs(event.deadline).tz(eventTimezone).format('llll')
		: 'as soon as possible'
	const buildDocumentDescription = event?.id
		? event.status == 'published' || event.status == 'publishing'
			? `We made an unforgettable VidDay video 🎁 have a look.`
			: `We're compiling video messages for ${recipients.formatted}. Join in — upload your video message ${eventDeadline}.`
		: 'Group Video Gifts Made Simple! 🥳 Easily create personalized video gifts for special occasions: birthdays, weddings, anniversaries, retirement & more 🎉'

	const jsonSchema = `
	{
		"@context": "http://schema.org",
		"@type": "SoftwareApplication",
		"name": "Create Your Own Gift Video for Special Occasions | VidDay 🎁",
		"operatingSystem": "Windows, OS X, Linux, Android, iOS",
		"applicationCategory": "WebApplication",
		"applicationSubCategory": "http://www.vidday.com",
		"image": "https://static.vidday.com/seo_images/VidDay_Birthday_Video_Montage.webp",
		"description": "VidDay, a video maker for any special occasion! Get everyone to join in on the surprise video to celebrate that special day — no app to download! Live Support.",
		"offers": {
			"@type": "Offer",
			"price": "5.00",
			"priceCurrency": "USD",
			"url": "https://share.vidday.com/start-a-vidday"
		},
		"creator": {
			"@context": "http://schema.org",
			"@type": "Organization",
			"name": "Vidday Media Inc.",
			"alternateName": "VidDay",
			"url": "https://share.vidday.com",
			"logo": "/assets/images/vidday_logo.svg",
			"sameAs": [
				"https://www.facebook.com/ViddayGift",
				"https://twitter.com/ViddayGift",
				"https://www.instagram.com/viddaygift/",
				"https://www.youtube.com/channel/UCoq7kgNLjUTCL2b94zW-L1A",
				"https://www.youtube.com/c/Vidday",
				"https://www.pinterest.ca/ViddayGift/"
			]
		},
		"aggregateRating": {
			"@type": "AggregateRating",
			"ratingValue": "4.9",
			"ratingCount": "1823"
		}
	}
	`

	return (
		<Helmet>
			<title>{buildDocumentTitle()}</title>
			<meta property="og:type" content="website" />
			<meta property="og:title" content={buildDocumentTitle()} />
			<meta property="og:url" content={url} />
			<meta property="og:image:secure_url" content={buildDocumentImage} />
			<meta property="og:image" itemProp="image" content={buildDocumentImage} />
			<meta property="og:image:alt" content={buildDocumentTitle()} />
			{/* {publishedMediaViewable && <meta property="og:video" content={buildDocumentVideo} />}  Removed to prevent viewing of video in sms app, bring them to the site*/}
			<meta property="og:description" content={buildDocumentDescription} />

			<meta property="fb:app_id" content="1826421140802874" />

			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content="@viddaygift" />
			<meta name="twitter:title" content={buildDocumentTitle()} />
			<meta name="twitter:description" content={buildDocumentDescription} />
			<meta name="twitter:image" content={buildDocumentImage} />

			<meta name="robots" content="noindex dofollow noarchive" />

			<script type="application/ld+json">{jsonSchema}</script>
		</Helmet>
	)
}
export default MetaTagsPublic

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchNotifications } from '../../api/notifications/actions'
import { fetchTracks } from '../../api/app/media/actions'
import { fetchInvoices } from '../../api/app/invoices'
import { useSocketContext } from './SocketProvider'
import { LOGOUT_SUCCESS } from '../../api/app/constants'

// PoC, this component needs to be split up and moved elsewhere;
// e.g. the 'fetchNotifications' part could be split out and inserted into the Notifications component
// temporarily, we will just stack everything in here
const GlobalSocketListeners = ({ children }) => {
	const dispatch = useDispatch()
	const {
		users: { socket: usersSocket },
	} = useSocketContext()

	/** define handlers */
	const handleFetchNotifications = (payload) => {
		console.info(`Socket ${usersSocket.id} received "fetchNotifications" with payload : `, payload)
		dispatch(
			fetchNotifications({
				orderBy: 'createdAt',
				orderByDir: 'desc',
			})
		)
	}

	const handleFetchTracks = (payload) => {
		console.info(`Socket ${usersSocket.id} received "fetchTracks" with payload : `, payload)
		dispatch(fetchTracks())
	}

	// Invoice Object
	const handleFetchInvoices = (payload) => {
		dispatch(fetchInvoices())
	}

	// logout from all open tabs
	const handleLogout = (payload) => {
		dispatch({ type: LOGOUT_SUCCESS })
	}

	/** register listeners when mounting */
	useEffect(() => {
		usersSocket.on('fetchNotifications', handleFetchNotifications)
		usersSocket.on('fetchTracks', handleFetchTracks)
		usersSocket.on('fetchInvoices', handleFetchInvoices)
		usersSocket.on('logout', handleLogout)

		/** deregister listeners when unmounting */
		return () => {
			usersSocket.off('fetchNotifications')
			usersSocket.off('fetchTracks')
			usersSocket.off('fetchInvoices')
			usersSocket.off('logout', handleLogout)
		}
	}, [])

	return children
}

export default GlobalSocketListeners
